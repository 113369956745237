
/* Grid layout */
.flexRoot {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.flexContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.flexFill {
    flex: 1;
}

/* Scrollable containers */
.scrollableContainer {
    overflow: auto;
    height: 100%;
}
.scrollableContent {
    height: 1px;
}

/* My table */
table.myTable {
    width: 100%;
    /*table-layout: fixed;*/
    border-collapse: collapse;
}

table.myTable > tbody > tr:hover {
    background-color: #e0e0e0;
}

table.myTable > tbody > tr.selectedRow {
    background-color: #c0c0c0;
}

table.myTable > tbody > tr > td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 6px;

   /* This adds ellipses to long td content. */
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

table.myTable > tbody > tr > td.mobileArrow {
    text-align: right;
    max-width: inherit;
}

.no-display {
    display: none;
}

.no-visible {
    visibility: hidden;
}